<template>
  <HomeScreen />
</template>

<script setup lang="ts">
import HomeScreen from '@/modules/home/screen/home_screen.vue'
import { useLastChanged } from '@vueuse/core';

useHead({
  title: 'Cameo | Home'
})

definePageMeta({
  title: 'Home'
})

defineComponent({
  name: 'Home'
})
</script>
