<template>
  <section class="section-video">
    <div class="video">
      <video v-lazy-load 
        data-src="/video/cameo.mp4"
        autoplay loop autobuffer muted playsinline
      />
    </div>

    <div class="container mx-auto px-5">
      <div class="max-w-[400px]">
        <nuxt-img
          src="/images/cameo/cameo.svg"
          alt="cameo"
          class="w-52 md:w-64 lg:w-80 mb-3"
        />
        <p class="text-base sm:text-xl md:text-2xl opacity-50 mb-8">
          We create fun, entertaining videos to aspire & influence people
        </p>
        <a
          href="https://www.youtube.com/cameoproject"
          target="_blank"
          class="btn secondary !flex items-center !pr-6 w-fit"
        >
          <nuxt-img
            src="/images/icons/play-arrow-red.svg"
            alt="play"
            class="mr-1"
          />
          See on Youtube
        </a>
      </div>
    </div>
  </section>

  <section class="container mx-auto px-5 py-16 lg:py-[100px]">
    <div class="max-w-xl lg:max-w-3xl mx-auto text-center">
      <p class="text-xs sm:text-base xl:text-xl opacity-50 mb-10 lg:mb-[100px]">
        Established in 2008, a group of young creative people pool their talents together to provide photography services called CAMEO. We then evolved when we took a dive into the digital world and created our own creative project. <br> Thus we became CAMEO Project.
      </p>

      <h1 class="text-base md:text-3xl lg:text-5xl font-semibold mb-10">
        Take a look at our channels
      </h1>

      <ul class="flex justify-between [&>li>img]:max-w-[42px] md:[&>li>img]:max-w-full [&>li>img]:mb-3 [&>li>p]:text-xs md:[&>li>p]:text-2xl [&>li>p]:font-medium mb-10 lg:mb-[100px]">
        <li>
          <img
            src="/images/icons/youtube-color.svg"
            alt="youtube"
            data-not-lazy
          >
          <p>1.1 M+</p>
        </li>
        <li>
          <img
            src="/images/icons/instagram-color.svg"
            alt="instagram"
            data-not-lazy
          >
          <p>102k+</p>
        </li>
        <li>
          <img
            src="/images/icons/facebook-color.svg"
            alt="facebook"
            data-not-lazy
          >
          <p>100k+</p>
        </li>
        <li>
          <img
            src="/images/icons/twitter-color.svg"
            alt="twitter"
            data-not-lazy
          >
          <p>8k+</p>
        </li>
        <li>
          <img
            src="/images/icons/tiktok-color.svg"
            alt="tiktok"
            data-not-lazy
          >
          <p>27k+</p>
        </li>
      </ul>

      <ul
        class="flex justify-between px-4 md:px-0 [&>li]:flex [&>li]:items-center text-left [&>li]:w-full [&>li>img]:max-w-[1rem] md:[&>li>img]:max-w-full
        [&>li>img]:mr-2 [&>li>p]:text-xs md:[&>li>p]:text-xl lg:[&>li>p]:text-2xl [&>li>p]:font-medium"
      >
        <li>
          <img
            src="/images/icons/user.svg"
            alt="subscribers"
            data-not-lazy
          >
          <p>1.1M+ Subscribers</p>
        </li>
        <li class="place-content-end">
          <img
            src="/images/icons/video.svg"
            alt="viewers"
            data-not-lazy
          >
          <p>150M+ views <br> Avg. 200k views per day</p>
        </li>
      </ul>
    </div>
  </section>

  <section class="container px-5 mx-auto">
    <div class="card-service mb-8 sm:mb-[50px]">
      <div class="content">
        <nuxt-img
          src="/images/cameo/cameo-production.png"
          alt="cameo production"
          class="w-32 mx-auto sm:mx-0 sm:w-40 lg:w-52 mb-3 sm:mb-5"
        />
        <p class="opacity-50 text-sm sm:text-base">
          Cameo Production is a creative production house that provides cinematography and videography services for various needs.
        </p>
      </div>
      
      <nuxt-img
        class="img-bg hidden sm:block"
        src="/images/services/production.png"
        alt="cameo production"
      />

      <nuxt-img
        class="img-bg w-full sm:hidden"
        src="/images/services/production-msite.png"
        alt="cameo production"
      />
    </div>

    <div class="card-service mb-8 sm:mb-[50px]">
      <div class="content">
        <nuxt-img
          src="/images/cameo/cameo-project.png"
          alt="cameo project"
          class="w-32 mx-auto sm:mx-0 sm:w-40 lg:w-52 mb-3 sm:mb-5"
        />
        <p class="opacity-50 text-sm sm:text-base mb-6 sm:mb-8">
          Cameo Project is a Youtube based content creator. We provide creative video contents, music production, and talent management.
        </p>
        <a
          href="https://www.youtube.com/cameoproject"
          target="_blank"
          class="btn primary !flex items-center !pr-6 w-fit mx-auto sm:mx-0"
        >
          <nuxt-img
            src="/images/icons/play-arrow-white.svg"
            alt="play"
            class="mr-1"
          />
          See Channel
        </a>
      </div>
      
      <nuxt-img
        class="img-bg hidden sm:block"
        src="/images/services/project.png"
        alt="cameo project"
      />

      <nuxt-img
        class="img-bg w-full sm:hidden"
        src="/images/services/project-msite.png"
        alt="cameo project"
      />
    </div>

    <div class="card-service mb-8 sm:mb-[50px]">
      <div class="content">
        <nuxt-img
          src="/images/cameo/cameo-studio.png"
          alt="cameo studio"
          class="w-32 mx-auto sm:mx-0 sm:w-40 lg:w-52 mb-3 sm:mb-5"
        />
        <p class="opacity-50 text-sm sm:text-base mb-6 sm:mb-8">
          An integrated post production studio based in Jakarta. We provide full service post production facilities from offline editing, online editing, color grading, scoring, finishing and mastering.
        </p>
        <NuxtLink
          to="/postproduction"
          class="btn primary !flex items-center !pl-6 w-fit mx-auto sm:mx-0"
        >
          Learn More
          <nuxt-img
            src="/images/icons/arrow.svg"
            alt="arrow"
            class="ml-1"
          />
        </NuxtLink>
      </div>
      
      <nuxt-img
        class="img-bg !p-0 !rounded-3xl"
        src="/images/services/studio.png"
        alt="cameo studio"
      />
    </div>
  </section>

  <section class="container mx-auto px-5 my-20 sm:my-[120px]">
    <div class="max-w-5xl mx-auto">
      <h1 class="text-center text-lg sm:text-2xl lg:text-[44px] mb-4 sm:mb-10 xl:mb-16 font-bold">
        Our Works
      </h1>

      <div
        v-for="(item, index) in listWork"
        :key="`work-${index}`"
        class="card-work first-of-type:mb-6 sm:first-of-type:mb-0 sm:hidden"
      >
        <div class="image">
          <nuxt-img
            :src="`/images/works/${item.image}`"
            :alt="item.title"
          />
        </div>
        <div class="content">
          <p class="title">
            {{ item.title }}
          </p>
          <p class="desc">
            {{ item.desc }}
          </p>
        </div>
      </div>
  
      <Swiper
        class="swiper-works !hidden sm:!block"
        :modules="[SwiperPagination]"
        :slides-per-view="'1'"
        :space-between="80"
        :pagination="{
          clickable: true,
        }"
        @swiper="onSwiperWorks"
      >
        <SwiperSlide
          v-for="(item, index) in listWork"
          :key="`work-${index}`"
        >
          <div class="card-work">
            <div class="image">
              <nuxt-img
                :src="`/images/works/${item.image}`"
                :alt="item.title"
              />
            </div>
            <div class="content">
              <p class="title">
                {{ item.title }}
              </p>
              <p class="desc">
                {{ item.desc }}
              </p>
            </div>
          </div>
        </SwiperSlide>
        <div class="absolute left-0 bottom-0 w-fit z-10">
          <button
            class="mr-8"
            :class="{'opacity-50 cursor-not-allowed': swiperWorks?.isBeginning}"
            @click="swiperWorks.slidePrev()"
          >
            <nuxt-img
              src="/images/icons/arrow.svg"
              class="w-6 rotate-180"
            />
          </button>
          <button
            :class="{'opacity-50 cursor-not-allowed': swiperWorks?.isEnd}"
            @click="swiperWorks.slideNext()"
          >
            <nuxt-img
              src="/images/icons/arrow.svg"
              class="w-6"
            />
          </button>
        </div>
      </Swiper>
    </div>
  </section>
    
  <section class="container mx-auto px-5 py-[50px] sm:py-[120px] text-center font-bold">
    <div>
      <h3 class="text-[18px] sm:text-[44px]">
        Our Clients
      </h3>
      <div class="relative my-[32px] sm:my-[78px]">
        <div
          class="absolute left-0 z-[10] w-[20%] h-full"
          style="background: linear-gradient(90deg,rgba(8, 7, 7, 0.84) 0%,rgba(8, 7, 7, 0) 100%);" 
        />
        <div
          class="absolute right-0 z-[10] w-[20%] h-full"
          style="background: linear-gradient(270deg,rgba(8, 7, 7, 0.84) 0%, rgba(8, 7, 7, 0) 100% ); "
        />
        <div>
          <h4 class="text-[12px] sm:text-[24px]">
            FMCG
          </h4>
          <div class="relative flex overflow-hidden mt-[8px] mb-[29px] sm:mt-[16px] sm:mb-[58px]">
            <div class="animate-marquee whitespace-nowrap flex">
              <div
                v-for="(item, index) in listClients.fmcg"
                :key="`fmcg-${index}`"
                class="flex items-center justify-center w-[182px] h-[106px]"
              >
                <img
                  class="w-[80px] sm:w-[100px] max-h-full"
                  :src="`/images/clients/fmcg/${item.image}`"
                  alt="item.name"
                  data-not-lazy
                >
              </div>
            </div>
            <div class="absolute top-0 animate-marquee2 whitespace-nowrap flex">
              <div
                v-for="(item, index) in listClients.fmcg"
                :key="`fmcg-${index}`"
                class="flex items-center justify-center w-[182px] h-[106px]"
              >
                <img
                  class="w-[80px] sm:w-[100px] max-h-full"
                  :src="`/images/clients/fmcg/${item.image}`"
                  alt="item.name"
                  data-not-lazy
                >
              </div>
            </div>
          </div>
        </div>
        <div>
          <h4 class="text-[12px] sm:text-[24px]">
            Government & NGO
          </h4>
          <div class="relative flex overflow-hidden mt-[8px] mb-[29px] sm:mt-[16px] sm:mb-[58px]">
            <div class="animate-marqueeReverse whitespace-nowrap flex">
              <div
                v-for="(item, index) in listClients.government"
                :key="`government-${index}`"
                class="flex items-center justify-center w-[182px] h-[106px]"
              >
                <img
                  class="w-[80px] sm:w-[100px] max-h-full"
                  :src="`/images/clients/government/${item.image}`"
                  alt="item.name"
                  data-not-lazy
                >
              </div>
              <div
                v-for="(item, index) in listClients.government"
                :key="`government-${index}`"
                class="flex items-center justify-center w-[182px] h-[106px]"
              >
                <img
                  class="w-[80px] sm:w-[100px] max-h-full"
                  :src="`/images/clients/government/${item.image}`"
                  alt="item.name"
                  data-not-lazy
                >
              </div>
            </div>
            <div class="absolute top-0 animate-marqueeReverse2 whitespace-nowrap flex">
              <div
                v-for="(item, index) in listClients.government"
                :key="`government-${index}`"
                class="flex items-center justify-center w-[182px] h-[106px]"
              >
                <img
                  class="w-[80px] sm:w-[100px] max-h-full"
                  :src="`/images/clients/government/${item.image}`"
                  alt="item.name"
                  data-not-lazy
                >
              </div>
              <div
                v-for="(item, index) in listClients.government"
                :key="`government-${index}`"
                class="flex items-center justify-center w-[182px] h-[106px]"
              >
                <img
                  class="w-[80px] sm:w-[100px] max-h-full"
                  :src="`/images/clients/government/${item.image}`"
                  alt="item.name"
                  data-not-lazy
                >
              </div>
            </div>
          </div>
        </div>
        <div>
          <h4 class="text-[12px] sm:text-[24px]">
            Digital
          </h4>
          <div class="relative flex overflow-hidden mt-[8px] mb-[29px] sm:mt-[16px] sm:mb-[58px]">
            <div class="animate-marquee whitespace-nowrap flex">
              <div
                v-for="(item, index) in listClients.digital"
                :key="`digital-${index}`"
                class="flex items-center justify-center w-[182px] h-[106px]"
              >
                <img
                  class="w-[80px] sm:w-[100px] max-h-full"
                  :src="`/images/clients/digital/${item.image}`"
                  alt="item.name"
                  data-not-lazy
                >
              </div>
            </div>
            <div class="absolute top-0 animate-marquee2 whitespace-nowrap flex">
              <div
                v-for="(item, index) in listClients.digital"
                :key="`digital-${index}`"
                class="flex items-center justify-center w-[182px] h-[106px]"
              >
                <img
                  class="w-[80px] sm:w-[100px] max-h-full"
                  :src="`/images/clients/digital/${item.image}`"
                  alt="item.name"
                  data-not-lazy
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <GlobalContactUs />
</template>

<script setup lang="ts">
interface work {
  title: String,
  desc: String,
  image: String
}

export interface client {
  name: string
  image: string
}

export interface clients {
  fmcg: client[]
  government: client[]
  digital: client[]
}

const listWork: work[] = ref([
  {
    title: 'Digital Content & Advertising',
    desc: 'Developing creative ideas into content and strategy to optimize brand value, increasing brand effectiveness and market visibility. We produce a Digital Commercial, Web Serial, Music Video, Variety Show, and many more with fun and comedy concept as our identity.',
    image: 'digital-ads.png'
  },
  {
    title: 'Film & Series',
    desc: 'Producing film and original series with our experienced filmmakers who will work closely with you from ideation to execution, handle every aspect of the production process to create and developing stories into high quality audio visual.',
    image: 'film.jpg'
  },
])

const swiperWorks = ref(null)
const onSwiperWorks = (swiper: any) => {
  swiperWorks.value = swiper
}

const listClients: clients = reactive({
  fmcg: [
    {
      name: 'kapal-api',
      image: 'kapal-api.png'
    },
    {
      name: 'astra-honda-motor',
      image: 'ahm.png'
    },
    {
      name: 'astra-international',
      image: 'astra.png'
    },
    {
      name: 'danone',
      image: 'danone.png'
    },
    {
      name: 'heineken',
      image: 'heineken.png'
    },
    {
      name: 'kao',
      image: 'kao.png'
    },
    {
      name: 'kara',
      image: 'kara.png'
    },
    {
      name: 'kulo',
      image: 'kulo.png'
    }
  ],
  government: [
    {
      name: "cips",
      image: 'cips.png'
    },
    {
      name: 'jababeka',
      image: 'jababeka.png'
    },
    {
      name: 'kominfo',
      image: 'kominfo.png'
    },
    {
      name: 'metro-jaya',
      image: 'metro-jaya.png'
    },
    {
      name: 'rwi',
      image: 'rwi.png'
    },
    {
      name: 'siber-bekasi',
      image: 'siber-bekasi.png'
    }
  ],
  digital: [
    {
      name: 'bukalapak',
      image: 'bukalapak.png'
    },
    {
      name: 'google',
      image: 'google.png'
    },
    {
      name: 'grab',
      image: 'grab.png'
    },
    {
      name: 'samsung',
      image: 'samsung.png'
    },
    {
      name: 'visionplus',
      image: 'visionplus.png'
    },
    {
      name: 'wehelpyou',
      image: 'wehelpyou.png'
    },
    {
      name: 'xl',
      image: 'xl.png'
    },
    {
      name: 'youtube',
      image: 'youtube.png'
    },
  ]
})
</script>